// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import { queryAll } from './utils/dom'
import EventBus from './core/EventBus'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import ScrollService from './services/Scroll'
import detectie from 'detectie'

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
const IE = detectie()

if ( IE || iOS) {
    ScrollService.useNative()
} else {
    ScrollService.useVirtual()
}

import { loadComponents, removeComponents } from './core/componentUtils';
loadComponents()

const id = window.location.hash.replace('#', '')
const anchor = document.getElementById(id)

if (id && anchor) {
    const box = anchor.getBoundingClientRect()

    ScrollService.setPosition(0, box.top + ScrollService.getScroll().y)
}

import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'

import Swup from 'swup';
//import swupGtmPlugin from 'swup/plugins/swupGtmPlugin'

if (!IE) {
    const header = document.querySelector('#header').offsetHeight
    const scrollStack = []
    let currentScroll = null

    function pushScrollPosition() {
        const location = window.location.href
        const offset = ScrollService.getScroll().y

        scrollStack.push({
            location,
            offset
        })

        if (scrollStack > 10) {
            scrollStack.shift()
        }
    }

    function popScrollPosition() {
        return scrollStack.pop()
    }

    const swup = new Swup({
        linkSelector: 'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([data-component]), a[href^="#"]:not([data-no-swup]):not([data-component])',
        elements: ['#swup', '#header-user', '#header-navigation', '#header-logo'],
        debugMode: false,
        doScrollingRightAway: false,
        animateScroll: false,
        preload: true,
        cache: false,
        //plugins: [swupGtmPlugin]
    });

    // components
    swup.on('willReplaceContent', event => {
        const elements = queryAll('[data-swup]')
        queryAll('[data-swup]').forEach(element => removeComponents(element))
        EventBus.emit('swup:beforecontentchange')
    })

    swup.on('contentReplaced', event => {
        Nette.reinit()
        const id = window.location.hash.replace('#', '')
        const anchor = document.getElementById(id)

        queryAll('[data-swup]').forEach(element => loadComponents(element))

        if (currentScroll) {
            setTimeout(() => {
                ScrollService.setPosition(0, currentScroll.offset)
                currentScroll = null
            }, 0)
        } else {
            ScrollService.setPosition(0, 0)
        }

        if ($.nette) {
            $.nette.load()
        }

        EventBus.emit('swup:aftercontentchange')
    })

    // scroll
    swup.on('willReplaceContent', event => {
        swup.options.animateScroll = false;
    });

    swup.on('animationOutStart', event => {
        $('[data-component=Nav]').removeClass('is-open');
    });

    swup.on('clickLink', () => {
        pushScrollPosition()
    })

    swup.on('popState', event => {
        const settings = popScrollPosition()

        if (settings?.location == window.location.href) {
            currentScroll = settings
        }
    })

    window.swup = swup
}